interface FinishedStepProps {
	telegramInviteLink: string | undefined
	areaHasBot: boolean | undefined
	areaUserCount: number
}

export function FinishedStepComponent(props: FinishedStepProps) {
	return (
		<div className="text-left space-y-2">
			<p className="block text-base font-normal text-gray-700">
				Welcome to hangaround.live!
			</p>
			<p className="block text-base font-normal text-gray-700">
				What's next?
			</p>
			<div className="text-sm space-y-4 pt-2">
				<div className="space-y-1">
					<p className="pl-4 font-bold">Link your Telegram account</p>
					{props.telegramInviteLink ? (
						<>
							<p>Make sure you have <a href="https://telegram.org" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">Telegram</a> installed. That's where all the talking will happen.</p>
							<p>Hit the button below. It will send you to Telegram.</p>
							<p>In Telegram, confirm linking to your hanground.live account.</p>
							{/* <div className="pt-1">
								<button
									onClick={() => window.open(props.telegramInviteLink, '_blank')}
									className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded ml-4"
								>
									Link Telegram Account
								</button>
							</div> */}
							<div className="pt-1">
								<a
									href={props.telegramInviteLink}
									target="_blank"
									rel="noopener noreferrer"
									className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded ml-4"
								>
									Link Telegram Account
								</a>
							</div>							
						</>
					) : (
						<p>No, scratch that, your Telegram is already linked. You rock!</p>
					)}
				</div>

				<div className="space-y-1">
					<p className="pl-4 font-bold">Receive group invites</p>
					{props.telegramInviteLink ? (
						<p>Once we find you a group, we'll let you know in email (and if by that time you've linked Telegram, then in Telegram too).</p>
					) : (
						<p>Once we find you a group, we'll let you know in email and Telegram.</p>
					)}
				</div>

				<div className="space-y-1">
					{props.areaHasBot ? (
						<>
							<p className="pl-4 font-bold">Our chatbot is supported in your area</p>
							<p>You can start talking to it right away in Telegram. Doing so will help us know you better, and find you more and better matching groups.</p>
							<p>The bot can also give you recommendations for passing the time while waiting for groups to form.</p>
						</>
					) : (
						<>
							<p className="pl-4 font-bold">Our chatbot is not supported in your area yet</p>
							<p>We can still find you chat groups.</p>
							<p>To update your profile, instead of using the chatbot, you can use this website.</p>
						</>
					)}
				</div>

				<div className="space-y-1">
					{props.areaUserCount < 1000 && (
						<>
							<p className="pl-4 font-bold">Limited number of users in your area</p>
							<p>We only have a limited number of users in your area yet. Finding you groups may take longer, and you may get invited to groups that only partially match your interests.</p>
							<p>Help us grow by spreading the word!</p>							
						</>
					)}
				</div>
			</div>

			{/* <ul className="list-disc pl-4 mb-6 space-y-2 text-sm">
				{props.telegramInviteLink ? (
					<>
						<li className="pl-2">Link your new hanground.live account to your Telegram account. That's where all the talking will happen.</li>
						<ul className="list-disc pl-6 mb-6 space-y-1">
							<li className="pl-2">Make sure you have <a href="https://telegram.org" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">Telegram</a> installed.</li>
							<li className="pl-2">Hit the button below. It will send you to Telegram.</li>
							<li className="pl-2">In Telegram, confirm linking to your hanground.live account.</li>
							<button
								onClick={() => window.open(props.telegramInviteLink, '_blank')}
								className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
							>
								Link Telegram Account
							</button>
						</ul>
					</>
				) : (
					<li className="pl-2">Your account is already linked to Telegram. You rock!</li>
				)}
				{props.areaUserCount < 1000 && (
					<>
						<li className="pl-2">We only have a limited number of users in your area yet.</li>
						<ul className="list-disc pl-6 mb-6 space-y-1">
							<li className="pl-2">Finding you groups may take longer, and you may get invited to groups that only partially match your interests.</li>
							<li className="pl-2">Help us grow by spreading the word!</li>							
						</ul>
					</>
				)}
				{props.areaHasBot ? (
					<>
						<li className="pl-2">Our chatbot is supported in your area.</li>
						<ul className="list-disc pl-6 mb-6 space-y-1">
							<li className="pl-2">You can start talking to it right away in Telegram.</li>
							<li className="pl-2">Doing so will help us know you better, and find you more and better matching groups.</li>
							<li className="pl-2">You can also get recommendations for passing the time while waiting for groups to form.</li>
						</ul>
					</>
				) : (
					<>
						<li className="pl-2">Our chatbot is not supported in your area yet.</li>
						<ul className="list-disc pl-6 mb-6 space-y-1">
							<li className="pl-2">We can still find you chat groups.</li>
							<li className="pl-2">To update your profile, instead of using the chatbot, you can use this website.</li>
						</ul>
					</>
				)}
				{props.telegramInviteLink ? (
					<li className="pl-2">Once we find you a group, we'll let you know in email (and if by that time you've linked Telegram, in Telegram too).</li>
				) : (
					<li className="pl-2">Once we find you a group, we'll let you know in email and Telegram.</li>
				)}
			</ul> */}
		</div>
	)
}
