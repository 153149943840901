
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { toast } from 'react-hot-toast'
import { Loader2 } from 'lucide-react'
import * as env from '@/env'

interface IntroductionStepProps {
	email: string
	jwtToken: string
	location: { lat: number; lng: number }
	introduction: string
	setIntroduction: (introduction: string) => void
	setCurrentStep: (step: number) => void
	setTelegramInviteLink: (telegramInviteLink: string) => void
}

export function IntroductionStepComponent(props: IntroductionStepProps) {
	const [isLoading, setIsLoading] = useState(false)
	const minLength = 0
	const maxLength = 4000

	const isValidIntroduction = (text: string): boolean => {
		return text.length >= minLength && text.length <= maxLength
	}

	const handleSubmit = async (): Promise<void> => {
		setIsLoading(true)
		try {
			const response = await fetch(`${env.baseUrl}/api/v1/create-user`, {
				method: 'POST',
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${props.jwtToken}` 
				},
				body: JSON.stringify({ 
					email: props.email, 
					introduction: props.introduction, 
					location: props.location 
				})
			})
			if (!response.ok) {
				throw new Error('Failed to create user')
			}

			const data = await response.json()
			props.setTelegramInviteLink(data.telegramInviteLink)

			props.setCurrentStep(5)
		} catch (error) {
			toast.error('Error creating user. Please try again.')
			console.error('Error creating user:', error)
		} finally {
			setIsLoading(false)
		}
	}

	const handleBack = (): void => {
		props.setCurrentStep(3)
	}

	return (
		<div className="space-y-4">
			<label className="block text-base font-normal text-gray-700">
				Please introduce yourself. What are your interests, hobbies, goals, values? What kind of people would you like to meet?
			</label>
			<label className="block text-sm font-normal text-gray-700">
				Later you'll be able to chat with our bot to refine all this, but give us something to start with. We'll use it to form small chat groups with a few others in your area who share similar interests.
			</label>
			<Textarea
				value={props.introduction}
				onChange={(e) => props.setIntroduction(e.target.value)}
				rows={6}
			/>
			<div className="text-sm text-gray-500">
				{props.introduction.length}/{maxLength} characters (minimum {minLength})
			</div>
			<div className="flex justify-between">
				<Button onClick={handleBack} className="bg-red-500 hover:bg-red-600 text-white flex items-center justify-center w-24">
					<ChevronLeft className="mr-2 h-4 w-4" />
					Back
				</Button>
				<Button
					onClick={handleSubmit}
					disabled={!isValidIntroduction(props.introduction) || isLoading}
					className="bg-green-500 hover:bg-green-600 text-white flex items-center justify-center w-32"
				>
					{isLoading ? (
						<>
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
						</>
					) : (
						<>
							Sign up!
							<ChevronRight className="ml-2 h-4 w-4" />
						</>
					)}
				</Button>
			</div>
		</div>
	)
}
