import React, { useState, useEffect } from 'react'
import Modal from './modal';
import Faq from './faq';
import Privacy from './privacy';
import Terms from './terms';
import Contact from './contact';

/*
	todo: 
	-	"with a preselected group"
	-	"no swiping, no matching, no bullshit"
	
	-	"no noisy online crowds, no endless swiping, no checkbox hell, 
		just you, your interests, and a handful of neighbors who share them"
*/

const taglines = [
	"tell us what you like, we tell you who to meet", 
	"skip the crowd, meet your tribe",
	"small group, big heart",
	"your people are looking for you",
]

export default function About() {
	const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);
	const [fade, setFade] = useState(false);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setFade(true)
			setTimeout(() => {
				setCurrentTaglineIndex((prevIndex) => (prevIndex + 1) % taglines.length)
				setFade(false)
			}, 500)
		}, 4000)

		return () => clearInterval(intervalId)
	}, [])

	return (
		<div className="flex flex-col items-center justify-between h-full overflow-y-auto text-gray-800 p-4">
			<div>
				<img src="/assets/logo.png" alt="logo" className="w-64 mx-auto mb-6"/>
				<div 
					id="tagline" 
					className={`text-center font-cambria italic text-xl text-rose-600 mb-6 transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}
				>
					{taglines[currentTaglineIndex]}
				</div>
				
				<p className="text-center mb-6">Join us in experimenting with a new way to find interesting people around you!</p>
				
				<ul className="list-disc pl-4 mb-6 space-y-1">
					<li className="pl-2">Sign up in one minute, for free: pick your area and write a few words about yourself (you can refine it by chatting with our bot).</li>
					<li className="pl-2">Get invited to small chat groups with people nearby, each focused on a topic you all share an interest in.</li>
					<li className="pl-2">Have a good chat, and if things click, take it offline!</li>
					<li className="pl-2">While waiting for new invites, get personalized recommendations from our bot -- books, movies, places, and more.</li>
				</ul>
				<p className="mb-6">Why?</p>
				<ul className="list-disc pl-4 mb-6 space-y-1">
					<li className="pl-2">There are more people you'd like around you than you think -- it's just hard to find them.</li>
					<li className="pl-2">Random encounters at the pub are too hit-or-miss.</li>
					<li className="pl-2">Social networks and online forums are crowded and noisy, and they ignore offline proximity.</li>
					<li className="pl-2">Review-swiping tons of profiles one by one on friend-finder apps is such a chore.</li>
					{/* <li className="pl-2">1:1 meetings are too much pressure / can be intimidating.</li> */}
					<li className="pl-2">We offer small groups of neighbors, pre-selected for their interest in topics you like.</li>
				</ul>	
			</div>

			<footer className="mt-auto pt-8 text-center text-gray-600">
				<p className="space-x-2">
					{/* <a href="faq.html" className="hover:underline">FAQ</a> */}
					<Modal triggerText="FAQ">
						<Faq />
					</Modal>
					{/* <span>|</span> */}
					<Modal triggerText="Terms">
						<Terms />
					</Modal>
					{/* <span>|</span> */}
					<Modal triggerText="Privacy">
						<Privacy />
					</Modal>
					{/* <span>|</span> */}
					{/* <a href="mailto:&#105;&#110;&#102;&#111;&#64;&#104;&#97;&#110;&#103;&#97;&#114;&#111;&#117;&#110;&#100;&#46;&#108;&#105;&#118;&#101;" className="hover:underline">Contact</a> */}
					<Modal triggerText="Contact">
						<Contact />
					</Modal>
				</p>
			</footer>
		</div>
	)
}
