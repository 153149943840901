
import { useState } from 'react'
import { Input } from '@/components/ui/input'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { toast } from 'react-hot-toast'
import { Loader2 } from 'lucide-react'

import { Button } from '@/components/ui/button'
import * as env from '@/env'

type OtpStepProps = {
	email: string
	signupId: string
	setCurrentStep: (step: number) => void
	setJwtToken: (token: string) => void
	otp: string
	setOtp: (otp: string) => void
	setLocation: (location: { lat: number, lng: number }) => void
	setHasValidLocation: (hasValidLocation: boolean) => void
	setIntroduction: (introduction: string) => void
}

export function OtpStepComponent(props: OtpStepProps) {
	const [isLoading, setIsLoading] = useState(false)

	const isValidOtp = (otp: string): boolean => {
		return /^\d{6}$/.test(otp)
	}

	const handleContinue = async (): Promise<void> => {
		setIsLoading(true)
		try {
			const response = await fetch(`${env.baseUrl}/api/v1/signup-verify-otp`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					email: props.email, 
					otp: props.otp, 
					signupId: props.signupId 
				})
			})
			if (!response.ok) {
				throw new Error('Failed to verify OTP')
			}

			const data = await response.json()

			props.setJwtToken(data.token)
			console.log('JWT Token:', data.token)

			if (data.location) {
				props.setLocation(data.location)
				props.setHasValidLocation(true)
			}
			if (data.introduction) {
				props.setIntroduction(data.introduction)
			}

			props.setCurrentStep(3)
		} catch (error) {
			toast.error('Error verifying OTP. Please try again.')
			console.error('Error verifying OTP:', error)
		} finally {
			setIsLoading(false)
		}
	}

	const handleBack = (): void => {
		props.setCurrentStep(1)
	}

	return (
		<div className="space-y-4">
			<label className="block text-basefont-normal text-gray-700">
				We have sent you an email with a 6-digit verification code; please enter it below. 
			</label>			
			<label className="block text-sm font-normal text-gray-700">
				This is to make sure that no one else can sign up using your email address. 
				If you can't find our email, check your Spam folder. 
				If still not found, please double-check your email address in the previous step, 
				and try again.
			</label>			
			<Input
				type="text"
				placeholder="123456"
				value={props.otp}
				onChange={(e) => props.setOtp(e.target.value)}
			/>
			<div className="flex justify-between">
				<Button
					onClick={handleBack}
					className="bg-red-500 hover:bg-red-600 text-white flex items-center justify-center w-24"
				>
					<ChevronLeft className="mr-2 h-4 w-4" />
					Back
				</Button>
				<Button
					onClick={handleContinue}
					disabled={!isValidOtp(props.otp) || isLoading}
					className="bg-green-500 hover:bg-green-600 text-white flex items-center justify-center w-32"
				>
					{isLoading ? (
						<>
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
						</>
					) : (
						<>
							Continue
							<ChevronRight className="ml-2 h-4 w-4" />
						</>
					)}
					{/* {isLoading ? 'Verifying...' : 'Continue'}
					<ChevronRight className="ml-2 h-4 w-4" /> */}
				</Button>
			</div>
		</div>
	)
}
