import { useState, ChangeEvent } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { ChevronRight } from 'lucide-react'
import { Turnstile } from '@marsidev/react-turnstile'
import * as env from '@/env'
import { toast } from 'react-hot-toast'
import { Loader2 } from 'lucide-react'

interface EmailStepComponentProps {
	email: string
	setEmail: (email: string) => void
	setCurrentStep: (step: number) => void
	setSignupId: (signupId: string) => void
}

export function EmailStepComponent({ email, setEmail, setCurrentStep, setSignupId }: EmailStepComponentProps) {
	const prodTurnstileSiteKey = '0x4AAAAAAAklWnwo0zUOtnIF'
	const devTurnstileSiteKey = '1x00000000000000000000AA'
	const turnstileSiteKey = env.isDevelopment ? devTurnstileSiteKey : prodTurnstileSiteKey

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [turnstileToken, setTurnstileToken] = useState<string | null>(null)
	const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
	const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false)
	const [acceptAge, setAcceptAge] = useState<boolean>(false)

	const isValidEmail = (email: string): boolean => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
	}

	const handleContinue = async (): Promise<void> => {
		setIsLoading(true)
		try {
			const response = await fetch(`${env.baseUrl}/api/v1/signup-request-otp`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email, turnstileToken })
			})
			if (!response.ok) {
				throw new Error('Failed to request OTP')
			}
			const data = await response.json()
			const signupId = data.signupId
			if (!signupId) {
				throw new Error('No signup ID returned')
			}
			setSignupId(signupId)
			setCurrentStep(2)
		} catch (error) {
			toast.error('Error requesting OTP. Please try again.')
			console.error('Error requesting OTP:', error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="space-y-4">
			<label className="block text-base font-normal text-gray-700">
				Please enter your email address.
			</label>			
			<label className="block text-sm font-normal text-gray-700">
				We'll use it to send you invites to join small chat groups, 
				around topics you'll like, with people nearby that share similar interests.
			</label>			
			<Input
				type="email"
				placeholder="your@email.com"
				value={email}
				onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
			/>
			<div className="space-y-2 pl-2">
				<div className="flex items-center">
					<input
						type="checkbox"
						id="terms"
						checked={acceptTerms}
						onChange={(e) => setAcceptTerms(e.target.checked)}
						className="mr-2"
					/>
					<label htmlFor="terms" className="text-sm text-gray-700">
						I accept the <a href="tos.html" target="_blank" className="text-blue-600 hover:underline">Terms of Service</a>
					</label>
				</div>
				<div className="flex items-center">
					<input
						type="checkbox"
						id="privacy"
						checked={acceptPrivacy}
						onChange={(e) => setAcceptPrivacy(e.target.checked)}
						className="mr-2"
					/>
					<label htmlFor="privacy" className="text-sm text-gray-700">
						I accept the <a href="privacy.html" target="_blank" className="text-blue-600 hover:underline">Privacy Policy</a>
					</label>
				</div>
				<div className="flex items-center">
					<input
						type="checkbox"
						id="age"
						checked={acceptAge}
						onChange={(e) => setAcceptAge(e.target.checked)}
						className="mr-2"
					/>
					<label htmlFor="age" className="text-sm text-gray-700">
						I confirm that I am 18 years or older
					</label>
				</div>
			</div>
			<Turnstile
				siteKey={turnstileSiteKey}
				onSuccess={(newToken) => setTurnstileToken(newToken)}
				onError={() => setTurnstileToken(null)}
				onExpire={() => setTurnstileToken(null)}
			/>
			<div className="flex justify-end">
				<Button
					onClick={handleContinue}
					disabled={!turnstileToken || !isValidEmail(email) || isLoading || !acceptTerms || !acceptPrivacy || !acceptAge}
					className="bg-green-500 hover:bg-green-600 text-white flex items-center justify-center w-32"
				>
					{isLoading ? (
						<>
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
						</>
					) : (
						<>
							Continue
							<ChevronRight className="ml-2 h-4 w-4" />
						</>
					)}
				</Button>
			</div>
		</div>
	)
}
