import About from './components/about'
import SignupPanel from './components/signup/signup-panel'
import { Toaster } from 'react-hot-toast'
import * as env from '@/env'

function App() {
	//	disable in production; will enable on launch
	if (env.isProduction) {
		return <div>Soon.</div>
	}

	return (
		<div className="min-h-screen flex items-center justify-center bg-white">
			{ 
				!env.isProduction && (
					<div className="fixed top-0 left-0 right-0 bg-[repeating-linear-gradient(45deg,#fbbf24,#fbbf24_10px,#000_10px,#000_20px)] text-black p-2 text-center font-bold">
						<p className="text-white text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{env.mode}</p>
						{/* <p className="text-white text-lg drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">Development Environment</p> */}
					</div>
				)
			}
			<div className={`container mx-auto p-4 max-w-6xl min-h-screen flex flex-col ${!env.isProduction ? 'mt-12' : ''}`}>
				<div className="flex-grow flex flex-col md:flex-row gap-4">
					<section className="flex-1 rounded-lg p-6">
						<About />
					</section>
					<div className="left-0 right-0 md:top-0 md:bottom-0 md:left-1/2 md:-translate-x-1/2">
						<div className="w-full h-px md:w-px md:h-full bg-gradient-to-r md:bg-gradient-to-b from-transparent via-gray-300 to-transparent"></div>
					</div>					
					<section className="flex-1 rounded-lg p-6">
						<h2 className="text-2xl font-bold mb-4">Get Started</h2>
						<SignupPanel />
					</section>
				</div>
			</div>
			<Toaster />
		</div>
	)
}

export default App
