import React, { useState } from 'react'
import { EmailStepComponent } from '@/components/signup/email-step'
import { OtpStepComponent } from '@/components/signup/otp-step'
import { LocationStepComponent } from '@/components/signup/location-step'
import { IntroductionStepComponent } from '@/components/signup/introduction-step'
import { FinishedStepComponent } from '@/components/signup/finished-step'

export default function SignupPanel() {
	const [currentStep, setCurrentStep] = useState(1)
	const [email, setEmail] = useState('')
	const [signupId, setSignupId] = useState('')
	const [otp, setOtp] = useState('')
	const [jwtToken, setJwtToken] = useState('')
	const [location, setLocation] = useState({ lat: 0, lng: 0 })
	const [hasValidLocation, setHasValidLocation] = useState(false)
	const [introduction, setIntroduction] = useState('')
	const [telegramInviteLink, setTelegramInviteLink] = useState<string | undefined>(undefined)
	const [areaHasBot, setAreaHasBot] = useState<boolean | undefined>(undefined)
	const [areaUserCount, setAreaUserCount] = useState(0)

	const steps = [
		{ number: 1, title: 'Enter your email address' },
		{ number: 2, title: 'Enter email verification code' },
		{ number: 3, title: 'Pick your area' },
		{ number: 4, title: 'Write a few words about yourself' },
		{ number: 5, title: 'Finished!' }
	]

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return <EmailStepComponent 
					email={email} 
					setEmail={setEmail} 
					setCurrentStep={setCurrentStep} 
					setSignupId={setSignupId}
				/>
			case 2:
				return <OtpStepComponent 
					email={email} 
					signupId={signupId}
					setCurrentStep={setCurrentStep} 
					setJwtToken={setJwtToken}
					otp={otp}
					setOtp={setOtp}
					setLocation={setLocation}
					setHasValidLocation={setHasValidLocation}
					setIntroduction={setIntroduction}
				/>
			case 3:
				return <LocationStepComponent 
					jwtToken={jwtToken}
					location={location} 
					setLocation={setLocation} 
					hasValidLocation={hasValidLocation}
					setHasValidLocation={setHasValidLocation}
					setCurrentStep={setCurrentStep} 
					areaHasBot={areaHasBot}
					setAreaHasBot={setAreaHasBot}
					areaUserCount={areaUserCount}
					setAreaUserCount={setAreaUserCount}
				/>
			case 4:
				return <IntroductionStepComponent 
					email={email}
					jwtToken={jwtToken}
					location={location}
					introduction={introduction}
					setIntroduction={setIntroduction}
					setCurrentStep={setCurrentStep}
					setTelegramInviteLink={setTelegramInviteLink}
				/>
			case 5:
				return <FinishedStepComponent 
					telegramInviteLink={telegramInviteLink}
					areaHasBot={areaHasBot}
					areaUserCount={areaUserCount}
				/>
			default:
				return null
		}
	}

	return (
		<div className="space-y-4">
			{steps.map((step) => (
				<div
					key={step.number}
					className={`p-4 border rounded-md ${
						currentStep === step.number 
							? 'border-rose-600 shadow-md' 
							: 'border-gray-200'
					}`}
				>
					<h3 className="font-semibold mb-4">
						{step.number}. {step.title}
					</h3>
					{currentStep === step.number && (
						<div className="mt-6">
							{renderStep()}
						</div>
					)}
				</div>
			))}
		</div>
	)
}
