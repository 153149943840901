import React from "react"
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
} from "@/components/ui/dialog"
import { Button } from '@/components/ui/button'

interface ModalProps {
	triggerText: string
	title?: string
	description?: string
	children: React.ReactNode
}

export default function Modal(props: ModalProps) {
	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="link" className="text-rose-600 hover:text-rose-600/80">{props.triggerText}</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{props.title}</DialogTitle>
					<DialogDescription>{props.description}</DialogDescription>
				</DialogHeader>
				{props.children}
			</DialogContent>
		</Dialog>
	)
}
