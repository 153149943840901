
export const mode = import.meta.env.MODE
export const isDevelopment = mode === 'development'
export const isStaging = mode === 'staging'
export const isProduction = mode === 'production'

export const baseUrl = getBaseUrl()

function getBaseUrl() {
	if (isDevelopment) {
		return ""
		// return "https://7apjrvyf0h.execute-api.us-east-1.amazonaws.com/chumbot-staging-api-stage"
	}
	if (isStaging) {
		return "https://7apjrvyf0h.execute-api.us-east-1.amazonaws.com/chumbot-staging-api-stage"
	}
	return "https://????????.execute-api.us-east-1.amazonaws.com/chumbot-prod-api-stage"
}


