import { useState, useRef, useEffect, useCallback } from 'react'
import { Loader2 } from 'lucide-react'
import { debounce } from 'lodash'
import { MapPin, ChevronLeft, ChevronRight } from 'lucide-react'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { Button } from '@/components/ui/button'
import * as env from '@/env'

interface LatLng {
	lat: number
	lng: number
}

interface LocationStepProps {
	jwtToken: string
	location: LatLng
	setLocation: (location: LatLng) => void
	hasValidLocation: boolean
	setHasValidLocation: (hasValidLocation: boolean) => void
	setCurrentStep: (step: number) => void
	areaHasBot: boolean | undefined
	setAreaHasBot: (areaHasBot: boolean | undefined) => void
	areaUserCount: number
	setAreaUserCount: (areaUserCount: number) => void
}

export function LocationStepComponent(props: LocationStepProps) {
	const [isMapLoaded, setIsMapLoaded] = useState(false)
	const mapRef = useRef<L.Map | null>(null)
	const markerRef = useRef<L.Marker | null>(null)

	const defaultLocation = { lat: 43.7, lng: -79.4 }

	useEffect(
		() => {
			if (mapRef.current) {
				mapRef.current.invalidateSize()
			}
		}, 
		[isMapLoaded])

	const checkAreaSupport = useCallback(
		debounce(async (location: LatLng) => {
			try {
				const response = await fetch(`${env.baseUrl}/api/v1/signup-get-area-support`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${props.jwtToken}` 
					},
					body: JSON.stringify({ location }),
				})
				if (!response.ok) {
					throw new Error('Failed to check area support')
				}
				const support = await response.json()
				console.log('area support response', support)
				props.setAreaHasBot(support.bot)
				props.setAreaUserCount(support.userCount)
			} catch (error) {
				console.error('Error checking area support:', error)
			}
		}, 500),
		[]
	)

	const mapDidLoad = (): void => {
		// when mapDidLoad gets called, the ref is not yet set, 
		// so we need to wait for the next tick of the event loop
		setTimeout(
			() => {
				setIsMapLoaded(true)

				if (props.hasValidLocation) {
					updateLocation(props.location)
				} else {
					mapRef.current?.locate({ setView: true, watch: true, maxZoom: 12 })
					mapRef.current?.once('locationfound', onLocationFound)
					mapRef.current?.once('locationerror', onLocationError)
				}
			}, 
			0)
	}

	const onLocationFound = (e: L.LocationEvent): void => {
		if (props.hasValidLocation)
			return
		mapRef.current?.stopLocate()
		updateLocation(e.latlng)
	}

	const onLocationError = (e: L.ErrorEvent): void => {
		console.error("Error getting geolocation:", e)
		mapRef.current?.setView(defaultLocation, mapRef.current?.getZoom() ?? 4)
		updateLocation(defaultLocation)
	}

	const didClickMap = (location: LatLng): void => {
		updateLocation(location)
	}

	const updateLocation = (location: LatLng): void => {
		props.setLocation(location)
		props.setHasValidLocation(true)

		if (markerRef.current) {
			markerRef.current.setLatLng(location)
		} else {
			markerRef.current = L.marker(location)
			if (mapRef.current) {
				markerRef.current.addTo(mapRef.current)
			}
		}

		checkAreaSupport(location)
	}

	const didClickContinue = (): void => {
		props.setCurrentStep(4)
	}

	const didClickBack = (): void => {
		props.setCurrentStep(2)
	}

	return (
		<div className="space-y-4">
			<label className="block text-base font-normal text-gray-700">
				Please select your approximate location on the map. 
			</label>			
			<label className="block text-sm font-normal text-gray-700">
				No need to be very precise, just your city or neighborhood will do.
				Your browser may ask you for permission to share your location. 
				Clicking "Allow" helps us find you automatically, 
				otherwise you can click on the map to select your location. 
				Once the blue marker is in the right area, you're good to go.
			</label>			
			<div className="h-64 relative">
				<MapContainer
					center={props.hasValidLocation ? props.location : defaultLocation}
					zoom={props.hasValidLocation ? 12 : 4}
					style={{ height: '100%', width: '100%', zIndex: 0 }}
					whenReady={mapDidLoad}
					ref={mapRef}
				>
					<TileLayer 
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
						opacity={props.hasValidLocation ? 1 : 0.25}
					/>
					<MapEvents didClickMap={didClickMap} />
				</MapContainer>
				{ !props.hasValidLocation && (
					<div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-10">
						<Loader2 className="h-8 w-8 animate-spin text-primary" />
					</div>
				)}
			</div>
			<ul className="list-disc pl-4 mb-6 space-y-1 text-sm">
				{ props.hasValidLocation && props.areaHasBot === false && (
					<li className="pl-2">Our chatbot is not supported in your area yet.</li>
				)}
				{ props.hasValidLocation && props.areaUserCount < 1000 && (
					<li className="pl-2">We only have a limited number of users in your area yet.</li>
				)}
			</ul>	
			<div className="flex justify-between">
				<Button onClick={didClickBack} className="bg-red-500 hover:bg-red-600 text-white flex items-center justify-center w-24">
					<ChevronLeft className="mr-2 h-4 w-4" />
					Back
				</Button>
				<Button onClick={didClickContinue} className="bg-green-500 hover:bg-green-600 text-white flex items-center justify-center w-32">
					Continue
					<ChevronRight className="ml-2 h-4 w-4" />
				</Button>
			</div>
		</div>
	)
}

interface MapEventsProps {
	didClickMap: (location: LatLng) => void
}

function MapEvents(props: MapEventsProps) {
	useMapEvents({
		click(e: L.LeafletMouseEvent) {
			props.didClickMap(e.latlng)
		},
	})
	return null
}
